import './App.css';
import React from 'react';
import Drop from './components/dropdown';
import Beschrijving from './components/beschrijving';
import Kenmerken from './components/kenmerken';
import Beschikbaarheid from './components/beschikbaarheid';
import Contact from './components/contact';
import FotoSlide from './components/carousel';
import Route from './components/Route';

// TODO
//  - MOBILE - kleiner tekst
//  - MOBILE - scale vertically image
//  - Kenmerken klik om meer te zien
//  - Kenmerken mobiel, align center
//  - margin bottom in dynamisch 

function App() {
  return (
        <div className="App">
          <header className="App-header" style={{height: window.innerHeight}}>
              <h1 className='App-title'>
                Welkom bij vakantiehuis Bij de hooibergen
              </h1>
              <Drop />
          </header>
          <body data-bs-spy="scroll" className="App-body">
            <Beschrijving/>
            <FotoSlide />
            <Kenmerken />
            <Contact />
            <Route />
            <Beschikbaarheid />
          </body>
        </div>
  );
}

export default App;
