import React from 'react';
import GoogleMapReact from 'google-map-react';
import Button from 'react-bootstrap/Button';

function createMapOptions(maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT

      },
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.HYBRID
    };
  }

function Route() {
    const lat = 52.449088
    const lng = 6.467395
    
    return (
    <div>
        <div id="route" className='route'>
            <h2>Route en locatie</h2>
            <Button href='https://goo.gl/maps/kiNPTxfLNRcxN9G3A' variant="primary" size="lg" style={{ marginTop: '10px', width: '100%' }}>Vind de route met Google maps</Button>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                defaultCenter={{lat: lat, lng: lng}}
                defaultZoom={13}
                options={createMapOptions}>
            <Marker lat={lat} lng={lng} text='some text' />
        </GoogleMapReact>
        </div>
    </div>
    )
}

const Marker = props => {
    return <div className="pin"></div>
}

export default Route;