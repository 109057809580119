import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownType from 'react-bootstrap/DropdownButton';

function Drop() {
  return (
    <DropdownType
    as={ButtonGroup}
    size="lg"
    title="Menu"
    variant="success"
    >
    <Dropdown.Item eventKey="1" href="#beschrijving" >Beschrijving</Dropdown.Item>
    <Dropdown.Item eventKey="2" href="#foto's">Foto's van de woning</Dropdown.Item>
    <Dropdown.Item eventKey="3" href="#kenmerken" >Voorzieningen</Dropdown.Item>
    <Dropdown.Item eventKey="4" href="#route" >Route</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item eventKey="5" href="#contactprijs">Contact en prijs</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item eventKey="6" href="#beschikbaarheid">Beschikbaarheid</Dropdown.Item>
    </DropdownType>
  );
}

export default Drop;