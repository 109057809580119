import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { Row, Col, Container} from 'react-bootstrap';

const Kenmerken = () => {
  const [expand, setExpand ] = useState(window.innerWidth > 768 ? true : false );

  const width = window.innerWidth < 768 ? window.innerWidth - 40 : '15rem'
  const margin = '15px 15px 15px 15px'

  return (
    <div id="kenmerken" className='kenmerken-paragraaf'>
      <h2 style={{ padding: '10px'}}>Voorzieningen</h2>

      <Container fluid>
        <Col md={1} />
        <Row md={6} >
        <Card style={{ width: width, margin: margin }}>
          <Card.Header>Algemeen</Card.Header>
          <ListGroup variant="flush">
            { ['Zelf inchecken mogelijk','Map met fiets en wandelroutes','Geen huisdieren toegestaan','rookvrije woning'].map( kenmerk => (
              <ListGroup.Item><b>{kenmerk}</b></ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
        {
          expand ? 
          <>
            <Card style={{ width: width, margin: margin }}>
              <Card.Header>Keuken</Card.Header>
              <ListGroup variant="flush">
                { ['Koelkast','Keramische kookplaat','Afzuiging','Diepvries','Waterkoker','Koffiezetapparaat','Keukenmachine','Borden, Bestek en pannen','Keukenhanddoeken'].map( kenmerk => (
                  <ListGroup.Item>{kenmerk}</ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
            <Card style={{ width: width, margin: margin }}>
              <Card.Header>Buiten</Card.Header>
              <ListGroup variant="flush">
                { ['Eigen ingang','Schuurtje fietsenstalling','Oplaadpunt voor E-bikes','Ruim terras met buitenverlichting','Tuinhuisje en Tuinzitje','Parasol','Tafel met twee loungestoelen','parkeerplaats naast het huisje'].map( kenmerk => (
                  <ListGroup.Item>{kenmerk}</ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
            <Card style={{ width: width, margin: margin }}>
              <Card.Header>Badkamer</Card.Header>
              <ListGroup variant="flush">
                { ['Aparte douchecel','Wastafel','Toilet','Fohn'].map( kenmerk => (
                  <ListGroup.Item>{kenmerk}</ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
            <Card style={{ width: width, margin: margin }}>
              <Card.Header>Slaapkamer</Card.Header>
              <ListGroup variant="flush">
                { ['2 x 1 persoonsbed','Twee dekbedden plus hoofdkussens','extra beddegoed'].map( kenmerk => (
                  <ListGroup.Item>{kenmerk}</ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
            <Card style={{ width: width, margin: margin }}>
              <Card.Header>Bijkeuken</Card.Header>
              <ListGroup variant="flush">
                { ['Wasmachine','Afwasmachine','Stofzuiger','Strijkplank/bout','Kapstok','Wasbak'].map( kenmerk => (
                  <ListGroup.Item>{kenmerk}</ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
            <Card style={{ width: width, margin: margin }}>
              <Card.Header>Veiligheid</Card.Header>
              <ListGroup variant="flush">
                { ['Rookmelders','Brandvertragingsdeken'].map( kenmerk => (
                  <ListGroup.Item>{kenmerk}</ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
            <Card style={{ width: width, margin: margin }}>
              <Card.Header>Woonkamer</Card.Header>
              <ListGroup variant="flush">
                { ['Comfortabel zitje','Eettafel met 4 stoelen'].map( kenmerk => (
                  <ListGroup.Item>{kenmerk}</ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
            <Card style={{ width: width, margin: margin }}>
              <Card.Header>Internet / elektronica</Card.Header>
              <ListGroup variant="flush">
                { ['TV/radio','Wifi'].map( kenmerk => (
                  <ListGroup.Item>{kenmerk}</ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </> : <></>
        }</Row>
       <Col md={1} />
      </Container>
      <Button variant="link" onClick={() => setExpand(!expand)}>{!expand ? <h6>bekijk inrichting en meer</h6>: <h6>alleen algemene kenmerken</h6> }</Button>  
    </div>
  )
};

export default Kenmerken