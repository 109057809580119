import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import badkamer from '../assets/images/badkamer_2.jpg'
import douche from '../assets/images/douche_2.jpg'
import wasdroog from '../assets/images/bijkeuken_2.jpg'
import ramen from '../assets/images/zicht_tuin_2.jpg'
import keuken from '../assets/images/binnen_keuken.jpg'
import slaap_oost from '../assets/images/binnen_slaapkamer_oost.jpg'
import slaap_noord from '../assets/images/binnen_slaapkamer_noord.jpg'
import haard from '../assets/images/binnen_woonkamer_haard.jpg'
import noord from '../assets/images/binnen_woonkamer_noord.jpg'
import oost from '../assets/images/binnen_woonkamer_oost.jpg'
import zuid from '../assets/images/binnen_woonkamer_zuid.jpg'
import zomer from '../assets/images/zomerblik_1.jpeg'
import achter1 from '../assets/images/achtertuin.jpeg'
import achter2 from '../assets/images/achtertuin_2.jpeg'
import achter3 from '../assets/images/achtertuin_3.jpg'

function FotoSlide() {
  return (
    <Carousel fade id="foto's">
      {[
        [achter1, 'achtertuin en terras'],
        [zomer, 'bij de hooibergen in de lente'],
        [achter2, 'achtertuin en terras'],
        [achter3, 'achtertuin en terras'],
        [haard, 'woonkamer met tv in de kast'],
        [noord, 'comfortabele zitgelegenheid'],
        [oost, 'zicht op de hooibergen met parkeergelegenheid'],
        [zuid, 'eettafel met uitzicht op de ruime tuin en eigen terras'],
        [badkamer, 'de badkamer'],
        [douche, 'de douche'],
        [ramen, 'Uitzicht over de velden'],
        [wasdroog, 'handige bijkeuken'],
        [keuken, 'ruime keuken'],
        [slaap_noord, 'slaapkamer'],
        [slaap_oost, 'ruime slaapkamer'],
      ].map((item) => (
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={item[0]}
            alt=""
          />
          <Carousel.Caption>
            <h3>{item[1]}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))   
      }
    </Carousel>
  );
}

export default FotoSlide;
