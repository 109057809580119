import React from 'react';

function Beschrijving() {
    return (
    <div className='Beschrijving-paragraaf'>
    <div style={{width:"30%"}}/>
    <div id="beschrijving">
        <h2>Vakantiewoning voor 2 personen</h2>
        <h6>Wilt u logeren in de tuin van Nederland, te weten Overijssel. Bij ons, de familie Abma, in de driehoek Ommen, Hellendoorn en Den Ham bent u aan het goede adres en wel in een natuurlijke, fijne en groene omgeving( oud boeren cultuurlandschap).</h6>
        <h6>In deze omgeving staat onze boerderij uit de 17e eeuw, met de kenmerken van het oude boerderij leven(kookhuisje, een oude buiten wc en 2 hooibergen).</h6>
        <h6>Het achterste gedeelte kan door u gehuurd worden als vakantieverblijf, de boerderij is in 1996 gerenoveerd. Dit gedeelte is omringd door rust en ruimte, u heeft het zicht op de schapenwei en diverse landerijen. Ook is er een ruim terras en meerdere zon en schaduw zitjes. Bij regen of een te felle zon kunt u schuilen in ons prieel.</h6>
        <h6>Het vakantieverblijf bestaat uit een zeer ruime kamer, met een open keuken, een 2 persoons slaapkamer en een aangrenzende badkamer. De eigen ingang gaat via een berging waar zowel de vaatwasser als de wasmachine staat.</h6>
        <h6>Uw auto kunt u bij de vakantiewoning parkeren en uw fietsen(e-bike’s kunnen opgeladen worden) kunnen bij ons veilig weg gezet worden. </h6>
        <h6>Denkt u aan een actieve wandel of fietsvakantie dan zijn er vele mogelijkheden, waarbij de Lemelerberg, Hellendoornseberg en het Vechtdal gebied centraal staan. In het Vechtdal gebied bevinden zich de plaatsen Ommen, Dalfsen en Hardenberg. Het Overijsselse Havezate-, Regge- en het Pieterpad doorkruizen ons gebied.</h6>
        <h6>Vanuit uw vakantieverblijf kunt al wandelend deelnemen aan de diverse vensterbank route’s, waarbij u verschillende mooie uitkijkjes heeft en waar de meanderende Regge centraal staat. Diverse afstanden zijn mogelijk.</h6>
        <h6>Den Ham ligt op 2 kilometer van uw verblijf met zijn karakteristieke brink, waar u horeca, supermarkten, bakkers, een fietsenmaker en meerdere winkels kunt vinden.</h6>
        <h6>Denkt u aan het bezoeken van steden dan zijn de Hanze steden Zutphen, Zwolle en Deventer bereikbaar. </h6>
    </div>
    <div style={{width:"30%"}} />
    </div>
    )
}

export default Beschrijving;
