import React from 'react';
import useGoogleSheets from 'use-google-sheets';
import Table from 'react-bootstrap/Table';

const Beschikbaarheid = () => {
  const { data, loading, error } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: 'Weken', headerRowIndex: 1 }],
  });

  console.log('data', data )

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error!</div>;
  }

  const Passed = date => {
    console.log('date', date)
    const input = date.split("-")
    const read_date = new Date(parseInt(input[2],10),parseInt(input[1],10) - 1,parseInt(input[0],10))
    console.log('read darte', read_date)

    const thresholdDate = new Date();
    thresholdDate.setFullYear(thresholdDate.getFullYear() + 1);

    console.log('thresholdDate', thresholdDate)

    if (!isNaN(read_date.getTime()) && read_date > new Date() && read_date < thresholdDate) {
        return true
    }
    else {
      return false
    }
  } 

  return (
  <div id='beschikbaarheid' className='Beschikbaarheid-paragraaf'>
    <h2>Beschikbaarheid {new Date().getFullYear()}/{new Date().getFullYear() + 1}</h2>
    <Table striped bordered hover responsive>
      <thead>
            <tr>
              <th>Week</th>
              <th>Datum</th>
              <th>Beschikbaarheid</th>
            </tr>
      </thead>
      <tbody>
      {data[0]['data'].map(d => 
          ( Passed(d['Begin']) ?
          <tr style={{ backgroundColor: d['Beschikbaarheid'] === '0' ? '#9FE2BF': '#DE3163'}}>
              <td>{d['Week']}</td>
              <td>{d['Begin'] + ' tot ' + d['Eind'] }</td>
              <td>{d['Beschikbaarheid'] === '0' ? 'Beschikbaar': 'Bezet'}</td>
          </tr>
          :
          <div></div>
          )
      )}
      </tbody>
    </Table>
  </div>);
};

export default Beschikbaarheid