import React from 'react';
import Alert from 'react-bootstrap/Alert';

function Contact() {
    return (
    <div className='Contact-paragraaf'>
    <div id="contactprijs">
        <h2>Contact en prijs</h2>
        <h5 style={{ margin: '3em 0 3em 0'}}>Interesse? Bel dan <a href="tel:0650460661">0650460661</a> of mail naar <a href="mailto:bijdehooibergen@gmail.com">bijdehooibergen@gmail.com</a>.</h5>
        <p style={{ color: 'darkgray'}}> Sietse & Riet Abma Hallerhoek 10,7683PG, Den Ham (ov)</p>
        <p />
        <p>Van 1 april tot 31 oktober voor minimaal 7 dagen te huren voor €600,- per week.</p>
        {/* <p>Van 1 november tot 31 maart minimaal 3 dagen te huren voor €85,- per dag.</p> */}
        <p>Inchecken: vanaf 15 uur s'middags.</p>
        <p>Uitchecken: voor 10 uur s'ochtends.</p>
        { /* <Alert style={{ margin: '60px 0 60px 0' }} variant='success'> €85,- per dag</Alert> */}
        <p>Prijzen inclusief schoonmaak, gas, water, electra, exclusief toeristenbelasting à €1.15,-.</p>
        
        <p>Lakens te huren voor €8 pp.</p>
    </div >
    </div>
    )
}

export default Contact;
